import React from "react";
import { ROUTES } from "../constants/routes";
import PropTypes from "prop-types";

function Header({ light }) {
  return (
    <header>
      <nav className={`panel top ${light ? "unflux-light" : "unflux-dark"}`}>
        <div className="sections desktop">
          <div className="left">
            <a href={ROUTES.HOME} title="The Unflux">
              <svg
                style={{ width: "123px", height: "70px" }}
                id="logo"
                viewBox="0 0 225 44"
              >
                <use
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  xlinkHref="/assets/svg/icons.svg#logo"
                ></use>
              </svg>
            </a>
          </div>
          <div className="right">
            <ul className="menu trim">
              <li>
                <a href={ROUTES.ENROLL}>Enroll</a>
              </li>
              <li>
                <a href={ROUTES.LEARN}>Topics</a>
              </li>
              <li>
                <a href={ROUTES.ABOUT}>About</a>
              </li>
              {/* <li className="cropRight">
                <a className={"trimRight shift-up-5"} href="#">
                  <svg>
                    <use
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      xlinkHref="/assets/svg/icons.svg#search"
                    ></use>
                  </svg>
                </a>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="sections compact hidden">
          <div className="left">
            <a href={ROUTES.HOME} title="The Unflux">
              <svg id="logo" style={{ width: "123px", height: "54px" }}>
                <use
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  xlinkHref="/assets/svg/icons.svg#logo"
                ></use>
              </svg>
            </a>
          </div>
          <div className="right">
            <span
              className="button actionButton sidebarTrigger"
              data-sidebar-id="1"
            >
              <svg>
                <use
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  xlinkHref="/assets/svg/icons.svg#menu"
                ></use>
              </svg>
            </span>
          </div>
        </div>
      </nav>
      {/* Sidebar */}
      <nav className="sidebar" data-sidebar-id="1">
        <div className="close">
          <svg>
            <use
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xlinkHref="/assets/svg/icons.svg#close"
            ></use>
          </svg>
        </div>
        <div className="content">
          <a href="https://theunflux.com" className="logo">
            <svg width="100" height="30">
              <use
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xlinkHref="/assets/svg/icons.svg#logo"
              ></use>
            </svg>
          </a>
          <ul className="mainMenu margin-top-3">
            <li>
              <a href={ROUTES.ENROLL}>Enroll</a>
            </li>
            <li>
              <a href={ROUTES.LEARN}>Topics</a>
            </li>
            <li>
              <a href={ROUTES.ABOUT}>About</a>
            </li>
          </ul>
          {/* <ul class="social opacity-8">*/}
          {/*   <li><a href="#"><svg>*/}
          {/*             <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#facebook"></use>*/}
          {/*          </svg></a></li>*/}
          {/*    <li><a href="#"><svg>*/}
          {/*             <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#twitter"></use>*/}
          {/*          </svg></a></li>*/}
          {/*    <li><a href="#"><svg>*/}
          {/*             <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#instagram"></use>*/}
          {/*          </svg></a></li>*/}
          {/* </ul> -->*/}
        </div>
      </nav>
    </header>
  );
}

Header.propTypes = {
  light: PropTypes.bool,
};

export default Header;
